import React, { useState, useRef, useEffect } from "react";
import "./App.css";

import TSXOptionsComponent from "./tsxOptionsComponent";
import TSXDescriptionsComponent from "./tsxDescriptionsComponent";

const options = [
  "incremental", // Basic Options (0)
  "target",
  "module",
  "lib",
  "allowJs",
  "checkJs",
  "jsx",
  "declaration",
  "declarationMap",
  "sourceMap",
  "outFile",
  "outDir",
  "rootDir",
  "composite",
  "tsBuildInfoFile",
  "removeComments",
  "noEmit",
  "importHelpers",
  "downlevelIteration",
  "isolatedModules",
  "strict", // Strict Type Checkign Options (20)
  "noImplicitAny",
  "strictNullChecks",
  "strictFunctionTypes",
  "strictBindCallApply",
  "strictPropertyInitialization",
  "noImplicitThis",
  "alwaysStrict",
  "noUnusedLocals", // Additional Options (28)
  "noUnusedParameters",
  "noImplicitReturns",
  "noFallthroughCasesInSwitch",
  "moduleResolution", // Module Resolution Options (32)
  "baseUrl",
  "paths",
  "rootDirs",
  "typeRoots",
  "types",
  "allowSyntheticDefaultImports",
  "esModuleInterop",
  "preserveSymlinks",
  "allowUmdGlobalAccess",
  "sourceRoot", // Source Map Options (42)
  "mapRoot",
  "inlineSourceMap",
  "inlineSources",
  "experimentalDecorators", // Advanced Options (46)
  "emitDecoratorMetadata",
];

const getTitleOfOptions = (index: number) => {
  switch (true) {
    case index < 20:
      return (
        <span>
          Basic <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1}</b>/20
            </span>
          </span>
        </span>
      );
    case index >= 20 && index < 28:
      return (
        <span>
          Strict Type Checking <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1 - 20}</b>/8
            </span>
          </span>
        </span>
      );
    case index >= 28 && index < 32:
      return (
        <span>
          Additional
          <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1 - 28}</b>/4
            </span>
          </span>
        </span>
      );
    case index >= 32 && index < 42:
      return (
        <span>
          Module Resolution
          <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1 - 32}</b>/10
            </span>
          </span>
        </span>
      );
    case index >= 42 && index < 46:
      return (
        <span>
          Source Map
          <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1 - 42}</b>/4
            </span>
          </span>
        </span>
      );
    case index >= 46:
      return (
        <span>
          Advanced
          <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1 - 46}</b>/3
            </span>
          </span>
        </span>
      );
    default:
      return "Options";
  }
};

function App() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);
  const logAndProcessSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const searchTerm = e.currentTarget.value;
    const filteredOption = options.filter((option) => {
      return option.startsWith(searchTerm);
    })[0];

    const index = options.indexOf(filteredOption);
    setActiveIndex(index);
  };

  const [theme, setTheme] = useState<"dark" | "light">("dark");

  const toggleTheme = () => {
    console.log("toggleTheme");
    setTheme((theme) => (theme === "dark" ? "light" : "dark"));
  };

  useEffect(() => {
    if (theme === "light") {
      document.documentElement.style.setProperty("--teal-color", "#00c59f");
      document.documentElement.style.setProperty("--pink-color", "#bd66b1");
      document.documentElement.style.setProperty("--yellow-color", "#d3ac61");
      document.documentElement.style.setProperty(
        "--dark-gray-color",
        "#fafafa"
      );
      document.documentElement.style.setProperty(
        "--light-gray-color",
        "#909090"
      );
      document.documentElement.style.setProperty(
        "--x-light-gray-color",
        "#1a1a1a"
      );
      document.documentElement.style.setProperty("--color-true", "#4b8d3b");
      document.documentElement.style.setProperty("--color-false", "#ff6958");
    }
    if (theme === "dark") {
      document.documentElement.style.setProperty("--teal-color", "#00c59f");
      document.documentElement.style.setProperty("--pink-color", "#bd66b1");
      document.documentElement.style.setProperty("--yellow-color", "#d3ac61");
      document.documentElement.style.setProperty(
        "--dark-gray-color",
        "#171717"
      );
      document.documentElement.style.setProperty(
        "--light-gray-color",
        "#363636"
      );
      document.documentElement.style.setProperty(
        "--x-light-gray-color",
        "#efefef"
      );
      document.documentElement.style.setProperty("--color-true", " #4b8d3b");
      document.documentElement.style.setProperty("--color-false", " #ff6958");
    }
  }, [theme]);
  return (
    <div className="app">
      <header>
        <span className="title-header">
          <h1 onClick={toggleTheme} className="logo">
            tsconfig.json <span className="text">demystified</span>
          </h1>
        </span>
      </header>
      <main>
        <h2>
          An Interactive <code>tsconfig.json</code>{" "}
          <span style={{ color: "#00c59f" }}>
            <a href="https://generator.tsconfigdemystified.com/">
              generator
            </a>{" "}
          </span>
        </h2>
        <h2>
          A data{" "}
          <span style={{ color: "#00c59f" }}>
            <a href="https://analysis.tsconfigdemystified.com/">analysis</a>
          </span>{" "}
          of tsconfig usage across the world
        </h2>
        <h2>
          The deep-dive{" "}
          <span style={{ color: "#00c59f" }}>
            <a href="https://medium.com/@alextzinov/tsconfig-json-demystified-d8f333e578c1">
              blog post
            </a>
          </span>
        </h2>
      </main>
    </div>
  );
}

export default App;
